import { motion } from "framer-motion";
import { staggerItemsFade, itemFade } from "utils/animations";
import { RiCheckFill, RiCloseFill } from "react-icons/ri";

const QuizAnswers = ({
  gamesData,
  data,
  clickHandler,
  isFormSubmitted,
  isHintActivated,
  isAnswerCorrect,
}) => {
  return (
    <motion.div
      className={`answer-section row g-2 ${
        data.answerIsImg ? "answer-is-image" : "answer-is-text"
      }  `}
      variants={staggerItemsFade}
      initial="hidden"
      animate="show"
      exit="hidden">
      {data.answers.map((item, index) => (
        <motion.div
          key={index}
          className={`${
            item.isCorrect === false && isHintActivated ? "disable" : ""
          } ${data.answerIsImg ? "col-6" : "col-12"} mb-2`}
          variants={itemFade}>
          <motion.button
            type={`${gamesData.id === "quizbonus" ? "submit" : "button"}`}
            className={` btn w-100 btn-answer `}
            size="lg"
            id={"answer_" + index}
            onClick={(e) => clickHandler(e, item.isCorrect)}
            disabled={isFormSubmitted === true && "disabled"}>
            {item.isCorrect && (
              <span
                style={{ fontSize: "10px", top: "0", left: "0" }}
                className="position-absolute d-none">
                C
              </span>
            )}

            {data.answerIsImg ? (
              <img
                style={{ width: "100%" }}
                src={
                  process.env.PUBLIC_URL +
                  "/images/quiz/" +
                  gamesData.id +
                  "/" +
                  item.answer +
                  "?c3"
                }
              />
            ) : (
              item.answer
            )}

            <span className="animation animation__active"></span>
            {isFormSubmitted && (
              <>
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: isAnswerCorrect ? 0 : 1 }}
                  className="animation animation__correct">
                  <motion.span
                    initial={{ opacity: 0, scale: 5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1 }}>
                    <RiCheckFill className="icon" />
                  </motion.span>
                </motion.span>

                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0 }}
                  className="animation animation__wrong">
                  <motion.span
                    initial={{ opacity: 0, scale: 5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1 }}>
                    <RiCloseFill className="icon" />
                  </motion.span>
                </motion.span>
              </>
            )}
          </motion.button>
        </motion.div>
      ))}
    </motion.div>
  );
};

export default QuizAnswers;
